import React from "react"
import Styled from "styled-components"

const Container = Styled.div`
    display: flex;
    flex-direction: column;
    border: 2px solid #000000;
    padding: 1rem;
    &:before {
        content: " ";
        position: absolute;
        z-index: -1;
        top: 2px;
        left: 2px;
        right: 2px;
        bottom: 2px;
        border: 2px solid #000000;
    }
`

const MyContainer = ({ children }) => <Container>{children}</Container>

export default MyContainer
