import React from "react"
import { CardElement } from "react-stripe-elements"

import "./CardSection.css"

const style = {
  base: {
    color: "#32325d",
    fontFamily: '"Raleway-Light", "Helvetica Neue", Helvetica, sans-serif',
    fontSmoothing: "antialiased",
    fontSize: "16px",
    "::placeholder": {
      color: "#aab7c4",
    },
  },
  invalid: {
    color: "#fa755a",
    iconColor: "#fa755a",
  },
}

const CardSection = ({ label }) => (
  <div className="row">
    <div className="col-12">
      <label>{label}</label>
    </div>
    <div className="col-12">
      <CardElement style={style} />
    </div>
  </div>
)

export default CardSection
