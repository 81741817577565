const MONTHS = [
  `January`,
  `February`,
  `March`,
  `April`,
  `May`,
  `June`,
  `July`,
  `August`,
  `September`,
  `October`,
  `November`,
  `December`,
]
const MONTHS_ABV = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
]

export const FormatDateM = date => {
  let d = new Date(date)
  let m = d.getMonth()
  return MONTHS[m]
}
export const FormatDateMAbv = date => {
  let d = new Date(date)
  let m = d.getMonth()
  return MONTHS_ABV[m]
}
export const FormatDateD = (s, e) => {
  let start = new Date(s)
  const ds = start.getDate()
  let end = new Date(e)
  const de = end.getDate()
  return `${ds}-${de}`
}
export const FormatDateY = date => {
  let d = new Date(date)
  return d.getFullYear()
}
export const ProperCap = s => {
  return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase()
}
