import React from "react"
import { StripeProvider } from "react-stripe-elements"

import CheckOut from "./Checkout"
import { STRIPE } from "../../config"

const StripeWrapper = ({ children }) => (
  <StripeProvider apiKey={STRIPE.API_KEY}>
    <CheckOut>{children}</CheckOut>
  </StripeProvider>
)

export default StripeWrapper
