import { keyframes, css } from "styled-components"

// Keyframes
const BorderFillIn = keyframes`
    0% {
      stroke-dasharray: 600;
      stroke-dashoffset: 600;
    }
    60% {
      stroke-dasharray: 245;
      stroke-dashoffset: -240;
    }
    100% {
      stroke-dasharray: 0 500;
      stroke-dashoffset: -490;
    }
`
const BorderFillOut = keyframes`
    0% {
      stroke-dasharray: 0 500;
      stroke-dashoffset: -490;
    }
    40% {
      stroke-dasharray: 245;
      stroke-dashoffset: -240;
    }
    100% {
      stroke-dasharray: 600;
      stroke-dashoffset: 600;
    }
`
const RenderBorderAnimation = checked => {
  const bfi = css`
    ${BorderFillIn} forwards 0.75s
  `
  const bfo = css`
    ${BorderFillOut} forwards 0.75s
  `
  if (checked) return bfi
  return bfo
}
const MarkFillIn = keyframes`
    0% {
      stroke-dashoffset: 300;
      stroke-dasharray: 300;
    }
    100% {
      stroke-dasharray: 100, 100;
      stroke-dashoffset: -50;
    }
`
const MarkFillOut = keyframes`
    0% {
      stroke-dasharray: 100, 100;
      stroke-dashoffset: -50;
    }
    100% {
      stroke-dasharray: 300;
      stroke-dashoffset: 300;
    }
`
const RenderMarkAnimation = checked => {
  const mfi = css`
    ${MarkFillIn} 0.4s forwards
  `
  const mfo = css`
    ${MarkFillOut} 0.4s forwards
  `
  if (checked) return mfi
  return mfo
}
const BackgroundFillIn = keyframes`
    0% {
      background: #c0c0de;
    }
    100% {
      background: #242432;
    }
`
const BackgroundFillOut = keyframes`
    0% {
        background: #242432;
    }
    100% {
        background: #c0c0de;
    }
`
const RenderBackgroundAnimation = checked => {
  const bfi = css`
    ${BackgroundFillIn} 0.2s forwards
  `
  const bfo = css`
    ${BackgroundFillOut} 0.2s forwards
  `
  if (checked) return bfi
  return bfo
}

export {
  BorderFillIn,
  BorderFillOut,
  RenderBorderAnimation,
  MarkFillIn,
  MarkFillOut,
  RenderMarkAnimation,
  BackgroundFillIn,
  BackgroundFillOut,
  RenderBackgroundAnimation,
}
