import React from "react"
import Styled from "styled-components"

const Form = Styled.form`
	display: flex;
	flex-direction: column;
    justify-content: center;
    
    label {
        display: flex;
		justify-content: flex-start;
        margin-top: 15px;
    }
    p {
        display: flex;
        justify-content: flex-start;
        margin-top: 10px;
        color: red;
    }
	input, select, textarea {
		display: flex;
        justify-content: flex-start;
        width: 100%;
        margin-top: 10px;
        text-indent: 5px;
        background-color: ${({ theme }) => `${theme.primaryLight}`};
        border-color: rgb(166, 166, 166);
        border-width: 1px;
        border-style: solid;
    }
    select {
        height: 28px;
        border-radius: 0;
        /* for Firefox */
        -moz-appearance: none;
        /* for Chrome */
        -webkit-appearance: none;
        /* For IE10 */
        &::-ms-expand {
            display: none;
        }
    }

	input:focus, textarea:focus, select:focus {
		outline: ${({ theme }) =>
      theme ? `${theme.secondary} auto 5px` : "#000000 auto 5px"}
	}
	button {
		margin-top: 10px;
	}
`

const MyForm = ({ onSubmit, children }) => (
  <Form onSubmit={onSubmit}>{children}</Form>
)

export default MyForm
