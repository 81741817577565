import React from "react"
import Styled from "styled-components"
import {
  RenderBorderAnimation,
  RenderMarkAnimation,
  RenderBackgroundAnimation,
} from "./KeyFrames"

const HOVER_SCALE = `1.25`
const STROKE_SIZE = `0.5rem`
const RX = `1.5rem`

const SIZE = `3rem`
const WIDTH = `2rem`
const HEIGHT = `2rem`

const Container = Styled.div`
    margin-bottom: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
`
const Agreement = Styled.h4`
    display: flex;
    flex-direction: column;
    cursor: pointer;
    margin-left: 1rem;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 400;
    color: #000000;
    &:hover {
        opacity: 0.7;
    }
`
const Err = Styled.span`
    color: red;
`
const CheckBoxBackground = Styled.rect`
    z-index: 102;
    transform: translate(${STROKE_SIZE}, ${STROKE_SIZE});
    width: ${WIDTH};
    height: ${HEIGHT};
    transform-origin: center center;
    fill: #ffffff;
    transition: 0.2s;
`
const CheckBoxBorder = Styled.rect`
    z-index: 101;
    transform: translate(${STROKE_SIZE}, ${STROKE_SIZE});
    width: ${WIDTH};
    height: ${HEIGHT};
    transform-origin: center center;
    fill: #ffffff;
    stroke: ${({ checked }) => (checked ? `#242432` : `transparent`)};
    stroke-width: 1.2rem;
    stroke-dasharray: 600;
    stroke-dashoffset: 600;
    stroke-linecap: round;
    animation: ${({ checked }) => RenderBorderAnimation(checked)};
`
const CheckBoxCheck = Styled.path`
    z-index: 103;
    transform: rotate(-90deg) translate(calc(50% - 1.6rem), calc(-50% + 0.9rem)) scale(0.28);
    transform-origin: center center;
    fill: none;
    stroke: #242432;
    stroke-dasharray: 300;
    stroke-dashoffset: 300;
    stroke-width: 1rem;
    stroke-linecap: round;
    stroke-linejoin: round;
    animation: ${({ checked }) => RenderMarkAnimation(checked)};
`
const CheckBox = Styled.svg`
    width: ${SIZE};
    height: ${SIZE};
    background: #d8d8ea;
    position: relative;
    cursor: pointer;
    overflow: visible;
    transform: rotate(90deg);
    transition: 0.2s;
    animation: ${({ checked }) => RenderBackgroundAnimation(checked)};
    &:hover ${CheckBoxBackground} {
        transform: scale(${HOVER_SCALE}) translate(${STROKE_SIZE}, ${STROKE_SIZE});
    }
`

const MyCheckBox = ({ checked, onClick, error, text }) => (
  <Container>
    <CheckBox checked={checked} onClick={onClick}>
      <CheckBoxBorder checked={checked} rx={RX} />
      <CheckBoxBackground rx={RX} />
      <CheckBoxCheck
        checked={checked}
        d="M104.869,1.3,21.024,93.876.949,75.457"
      />
    </CheckBox>
    <Agreement onClick={onClick}>
      {text}
      {error && <Err>{error}</Err>}
    </Agreement>
  </Container>
)

export default MyCheckBox
