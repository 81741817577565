import React from "react"

const PhoneInput = ({ errors, ref1, ref2, ref3, next }) => {
  const handleInput = e => {
    // Length Detection & Focus Movement
    const a = document.getElementById("a")
    const b = document.getElementById("b")
    const c = document.getElementById("c")
    const d = document.getElementById(next)
    a.onkeyup = () => {
      if (a.value.length === parseInt(a.attributes["maxlength"].value)) {
        b.focus()
      }
    }
    b.onkeyup = () => {
      if (b.value.length === parseInt(b.attributes["maxlength"].value)) {
        c.focus()
      }
    }
    c.onkeyup = () => {
      if (
        a.value.length === parseInt(a.attributes["maxlength"].value) &&
        b.value.length === parseInt(b.attributes["maxlength"].value) &&
        c.value.length === parseInt(c.attributes["maxlength"].value)
      ) {
        d.focus()
      }
    }
  }

  return (
    <div className="row">
      <div className="col-12">
        <label htmlFor="phone1">Phone</label>
      </div>
      <div className="col-4">
        <input
          id={`a`}
          type="tel"
          placeholder="435"
          name="phone1"
          maxLength={`3`}
          onChange={handleInput}
          ref={ref1}
        />
        {errors.phone1 && <p>{errors.phone1.message}</p>}
      </div>
      <div className="col-4">
        <input
          id={`b`}
          type="tel"
          placeholder="668"
          name="phone2"
          maxLength={`3`}
          onChange={handleInput}
          ref={ref2}
        />
        {errors.phone2 && <p>{errors.phone2.message}</p>}
      </div>
      <div className="col-4">
        <input
          id={`c`}
          type="tel"
          placeholder="1234"
          name="phone3"
          maxLength={`4`}
          onChange={handleInput}
          ref={ref3}
        />
        {errors.phone3 && <p>{errors.phone3.message}</p>}
      </div>
    </div>
  )
}

export default PhoneInput
