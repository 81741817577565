import React, { useReducer, useEffect, useState } from "react"
import useForm from "react-hook-form"
import * as actions from "../../../../../redux/actions"
import { connect } from "react-redux"
import { injectStripe } from "react-stripe-elements"

import RuledBreak from "../../../../../components/RuledBreak"
import {
  CheckBoxContainer,
  CheckBox,
  RegisterForm as Form,
  PhoneInput,
  Danger as Err,
  Success,
} from "../../../../../components/Form"
import { BallTriangle as Loading } from "../../../../../components/Common/loaders"
import { CardSection } from "../../../../../components/Stripe"
import { ButtonTrace } from "../../../../../components/Button"

import { FormatDateMAbv, FormatDateD } from "../../../../../utils"

import {
  STATES,
  CARRIERS,
  REFERRALS,
  GROUP_VALID_PAYMENTS,
  LASH_OPTIONS,
} from "../../../../../config"

const initalAgreements = {
  one: { value: false, error: "" },
  two: { value: false, error: "" },
  three: { value: false, error: "" },
  four: { value: false, error: "" },
  five: { value: false, error: "" },
  six: { value: false, error: "" },
}

const reducer = (state, action) => {
  switch (action.type) {
    case "one":
      return {
        ...state,
        one: { value: action.payload.value, error: action.payload.error },
      }
    case "two":
      return {
        ...state,
        two: { value: action.payload.value, error: action.payload.error },
      }
    case "three":
      return {
        ...state,
        three: { value: action.payload.value, error: action.payload.error },
      }
    case "four":
      return {
        ...state,
        four: { value: action.payload.value, error: action.payload.error },
      }
    case "five":
      return {
        ...state,
        five: { value: action.payload.value, error: action.payload.error },
      }
    case "six":
      return {
        ...state,
        six: { value: action.payload.value, error: action.payload.error },
      }
  }
}

const GroupTrainingRegister = ({
  groupDates,
  stripe,
  alertClear,
  getFLP2DayTrainings,
  loading,
  registerFLP2DayTraining,
  groupTrainingRegSuccess,
  groupTrainingRegError,
}) => {
  const [cbState, dispatch] = useReducer(reducer, initalAgreements)
  const [isDisabled, setIsDisabled] = useState(false)
  const [agreementsError, setAgreementsError] = useState("")
  const [stripeError, setStripeError] = useState("")
  const { register, handleSubmit, errors, watch } = useForm()

  useEffect(() => {
    let mounted = true
    if (mounted) {
      alertClear()
      getFLP2DayTrainings(false)
    }
    return () => {
      mounted = false
    }
  }, [])

  const onSubmit = async data => {
    const { one, two, three, four, five, six } = cbState
    const {
      fname,
      lname,
      email,
      address,
      city,
      state,
      zip,
      phone1,
      phone2,
      phone3,
      carrier,
      referred,
      training,
      curlOne,
      curlTwo,
      payment,
    } = data
    // check / clean data
    setAgreementsError("")
    setStripeError("")
    setIsDisabled(true)
    const agreeErrors = {}
    // User Agreements
    if (!one.value) {
      agreeErrors.one = "You Must Agree"
      dispatch({
        type: "one",
        payload: { value: cbState.one.value, error: "You Must Agree" },
      })
      setAgreementsError("Check Agreements")
    }
    if (!two.value) {
      agreeErrors.two = "You Must Agree"
      dispatch({
        type: "two",
        payload: { value: cbState.two.value, error: "You Must Agree" },
      })
      setAgreementsError("Check Agreements")
    }
    if (!three.value) {
      agreeErrors.three = "You Must Agree"
      dispatch({
        type: "three",
        payload: { value: cbState.three.value, error: "You Must Agree" },
      })
      setAgreementsError("Check Agreements")
    }
    if (!four.value) {
      agreeErrors.four = "You Must Agree"
      dispatch({
        type: "four",
        payload: { value: cbState.four.value, error: "You Must Agree" },
      })
      setAgreementsError("Check Agreements")
    }
    if (!five.value) {
      agreeErrors.five = "You Must Agree"
      dispatch({
        type: "five",
        payload: { value: cbState.five.value, error: "You Must Agree" },
      })
      setAgreementsError("Check Agreements")
    }
    if (!six.value) {
      agreeErrors.size = "You Must Agree"
      dispatch({
        type: "six",
        payload: { value: cbState.six.value, error: "You Must Agree" },
      })
      setAgreementsError("Check Agreements")
    }
    if (Object.keys(agreeErrors).length === 0) {
      try {
        const token = await stripe.createToken({ email })
        registerFLP2DayTraining({
          fname,
          lname,
          email,
          address,
          city,
          state,
          zip,
          phone: parseInt(`${phone1}${phone2}${phone3}`),
          carrier,
          referred,
          training,
          curlOne,
          curlTwo,
          payment: parseInt(payment),
          stripeToken: token.token.id,
        })
        setIsDisabled(false)
      } catch (error) {
        console.log("Error Tokenizing Card")
        console.log(error)
        // return errors to user
        setStripeError("Error Charging Card")
        setIsDisabled(false)
      }
    } else {
      // return errors to user
      setAgreementsError("Please Check Agreements")
      setIsDisabled(false)
    }
  }

  const renderGroupDates = () => {
    if (groupDates) {
      const dates = groupDates.map(date => {
        const { _id, city, state, dateStart, dateEnd, spots } = date
        const month = FormatDateMAbv(dateStart)
        const days = FormatDateD(dateStart, dateEnd)
        return (
          <option key={_id} value={_id}>
            {city}, {state} | {month} {days} | Spots: {spots}
          </option>
        )
      })
      return <>{dates}</>
    } else {
      return <option value={"n/a"}>None Available</option>
    }
  }
  const renderButton = () => {
    if (loading) {
      return (
        <div className="row">
          <div className="col-12 text-center">
            <Loading className="ball-tri-loader" />
          </div>
        </div>
      )
    } else {
      return (
        <div className="row">
          <div className="col-12 text-center">
            <ButtonTrace
              disabled={isDisabled}
              onClick={handleSubmit(onSubmit)}
              btn={3}
            >
              Register Now
            </ButtonTrace>
          </div>
        </div>
      )
    }
  }
  const renderAlert = () => {
    if (stripeError) {
      return (
        <div className="row">
          <div className="col-12 text-center mt-4">
            <Err>{stripeError}</Err>
          </div>
        </div>
      )
    } else if (agreementsError) {
      return (
        <div className="row">
          <div className="col-12 text-center mt-4">
            <Err>{agreementsError}</Err>
          </div>
        </div>
      )
    } else if (groupTrainingRegSuccess) {
      return (
        <div className="row">
          <div className="col-12 text-center mt-4">
            <Success>{groupTrainingRegSuccess}</Success>
          </div>
        </div>
      )
    } else if (groupTrainingRegError) {
      return (
        <div className="row">
          <div className="col-12 text-center mt-4">
            <Err>{groupTrainingRegError}</Err>
          </div>
        </div>
      )
    }
  }
  return (
    <>
      <div className="row my-5">
        <div className="col-md-12 py-3">
          <RuledBreak>Group Training Registration</RuledBreak>
        </div>
      </div>
      <div className="row my-5">
        {/* AGREEMENTS */}
        <div className="col-md-6 col-lg-5 mr-auto ml-auto py-2 my-2 d-table">
          <h2 className="text-center">Agreements</h2>
          <h6 className="text-center">
            <i>You must agree to complete registration</i>
          </h6>
          <CheckBoxContainer>
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <CheckBox
                    text={`You have researched the state requirements in which you reside and agree that you are following your state laws of business and professional licensing. Four example; in Utah it is required to have either a Basic Aesthetics license or Cosmetology license in order to offer eyelash extensions to potential clients`}
                    error={cbState.one.error}
                    checked={cbState.one.value}
                    onClick={() => {
                      dispatch({
                        type: "one",
                        payload: { value: !cbState.one.value, error: "" },
                      })
                      setAgreementsError("")
                    }}
                  />
                  <CheckBox
                    text={`You are already certified in volume eyelash extensions. Tis is required to sign up for some of the online courses, each of the Fan Like a Pro online memberships, and the in-person Fan Like a Pro 2-Day Experience.`}
                    error={cbState.two.error}
                    checked={cbState.two.value}
                    onClick={() => {
                      dispatch({
                        type: "two",
                        payload: { value: !cbState.two.value, error: "" },
                      })
                      setAgreementsError("")
                    }}
                  />
                  <CheckBox
                    text={`You are at least 18 years old`}
                    error={cbState.three.error}
                    checked={cbState.three.value}
                    onClick={() => {
                      dispatch({
                        type: "three",
                        payload: { value: !cbState.three.value, error: "" },
                      })
                      setAgreementsError("")
                    }}
                  />
                  <CheckBox
                    text={`You agree to a non-refundable $500 minimal deposit.`}
                    error={cbState.four.error}
                    checked={cbState.four.value}
                    onClick={() => {
                      dispatch({
                        type: "four",
                        payload: { value: !cbState.four.value, error: "" },
                      })
                      setAgreementsError("")
                    }}
                  />
                  <CheckBox
                    text={`You agree to pay remaining balance (if any) in full two weeks prior to your training date.`}
                    error={cbState.five.error}
                    checked={cbState.five.value}
                    onClick={() => {
                      dispatch({
                        type: "five",
                        payload: { value: !cbState.five.value, error: "" },
                      })
                      setAgreementsError("")
                    }}
                  />
                  <CheckBox
                    text={`You agree to not record or copy any of the material presented within the training for use outside of your own personal use.`}
                    error={cbState.six.error}
                    checked={cbState.six.value}
                    onClick={() => {
                      dispatch({
                        type: "six",
                        payload: { value: !cbState.six.value, error: "" },
                      })
                      setAgreementsError("")
                    }}
                  />
                </div>
              </div>
            </div>
          </CheckBoxContainer>
        </div>
        {/* REGISTER FORM */}
        <div className="col-md-6 col-lg-5 mr-auto ml-auto py-2 my-2">
          <h2 className="text-center">Information</h2>
          <h6 className="text-center">
            <i>Please complete all fields</i>
          </h6>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <div className="container-fluid">
              {/* ----------------------------------------------------------------- */}
              <div className="row">
                <div className="col-12">
                  <label htmlFor="fname">First name</label>
                </div>

                <div className="col-12">
                  <input
                    type="text"
                    name="fname"
                    ref={register({
                      required: "Required",
                      maxLength: 80,
                    })}
                  />
                  {errors.fname && <p>{errors.fname.message}</p>}
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <label htmlFor="lname">Last name</label>
                </div>
                <div className="col-12">
                  <input
                    type="text"
                    name="lname"
                    ref={register({
                      required: "Required",
                      maxLength: 50,
                    })}
                  />
                  {errors.lname && <p>{errors.lname.message}</p>}
                </div>
              </div>
              {/* ----------------------------------------------------------------- */}
              <div className="row">
                <div className="col-12">
                  <label htmlFor="email">Email</label>
                </div>
                <div className="col-12">
                  <input
                    type="email"
                    name="email"
                    ref={register({
                      required: "Email Required",
                      pattern: {
                        value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        message: "Invalid email address",
                      },
                    })}
                  />
                  {errors.email && <p>{errors.email.message}</p>}
                </div>
              </div>
              {/* ----------------------------------------------------------------- */}
              <div className="row">
                <div className="col-12">
                  <label htmlFor="emailConfirm">Confirm Email</label>
                </div>
                <div className="col-12">
                  <input
                    type="email"
                    name="emailConfirm"
                    ref={register({
                      required: "Email Confirmation Required",
                      pattern: {
                        value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        message: "Invalid email address",
                      },
                      validate: value =>
                        value !== watch("email")
                          ? "Email & Confirm Email Do Not Match"
                          : undefined,
                    })}
                  />
                  {errors.emailConfirm && <p>{errors.emailConfirm.message}</p>}
                </div>
              </div>
              {/* ----------------------------------------------------------------- */}
              <div className="row">
                <div className="col-12">
                  <label htmlFor="address">Address</label>
                </div>
                <div className="col-12">
                  <input
                    type="text"
                    name="address"
                    ref={register({
                      required: "Required",
                    })}
                  />
                  {errors.address && <p>{errors.address.message}</p>}
                </div>
              </div>
              {/* ----------------------------------------------------------------- */}
              <div className="row">
                <div className="col-4">
                  <label htmlFor="city">City</label>
                </div>
                <div className="col-4">
                  <label htmlFor="state">State</label>
                </div>
                <div className="col-4">
                  <label htmlFor="zip">Zip</label>
                </div>
                <div className="col-4">
                  <input
                    type="text"
                    name="city"
                    ref={register({
                      required: "Required",
                    })}
                  />
                  {errors.city && <p>{errors.city.message}</p>}
                </div>
                <div className="col-4">
                  <select
                    name="state"
                    ref={register({
                      required: "Required",
                    })}
                  >
                    {STATES.map(state => (
                      <option key={state} value={state}>
                        {state}
                      </option>
                    ))}
                  </select>
                  {errors.state && <p>{errors.state.message}</p>}
                </div>
                <div className="col-4">
                  <input
                    type="number"
                    name="zip"
                    ref={register({
                      required: "Required",
                    })}
                  />
                  {errors.zip && <p>{errors.zip.message}</p>}
                </div>
              </div>
              {/* ----------------------------------------------------------------- */}
              <PhoneInput
                errors={errors}
                ref1={register({
                  required: "Required",
                  min: 3,
                  maxLength: 3,
                })}
                ref2={register({
                  required: "Required",
                  min: 3,
                  maxLength: 3,
                })}
                ref3={register({
                  required: "Required",
                  min: 4,
                  maxLength: 4,
                })}
                next={"carrier"}
              />
              {/* ----------------------------------------------------------------- */}
              <div className="row">
                <div className="col-12">
                  <label htmlFor="carrier">Phone Carrier</label>
                </div>
                <div className="col-12">
                  <select
                    id="carrier"
                    name="carrier"
                    ref={register({
                      required: "Required",
                    })}
                  >
                    {CARRIERS.map(carrier => (
                      <option key={carrier} value={carrier}>
                        {carrier}
                      </option>
                    ))}
                  </select>
                  {errors.carrier && <p>{errors.carrier.message}</p>}
                </div>
              </div>
              {/* ----------------------------------------------------------------- */}
              <div className="row">
                <div className="col-12">
                  <label htmlFor="referred">Referred By?</label>
                </div>
                <div className="col-12">
                  <select
                    name="referred"
                    ref={register({
                      required: "Required",
                    })}
                  >
                    {REFERRALS.map(referral => (
                      <option key={referral} value={referral}>
                        {referral}
                      </option>
                    ))}
                  </select>
                  {errors.referral && <p>{errors.referral.message}</p>}
                </div>
              </div>
              {/* ----------------------------------------------------------------- */}
              <div className="row">
                <div className="col-12">
                  <label htmlFor="training">Select Training</label>
                </div>
                <div className="col-12">
                  <select
                    name="training"
                    ref={register({
                      required: "Required",
                    })}
                  >
                    {renderGroupDates()}
                  </select>
                  {errors.training && <p>{errors.training.message}</p>}
                </div>
              </div>
              {/* ----------------------------------------------------------------- */}
              <div className="row">
                <div className="col-12">
                  <label htmlFor="curlOne">Select CURL #1</label>
                </div>
                <div className="col-12">
                  <select
                    name="curlOne"
                    ref={register({
                      required: "Required",
                    })}
                  >
                    {LASH_OPTIONS.map(lash => (
                      <option key={`${lash}_curlOne`} value={lash}>
                        {lash}
                      </option>
                    ))}
                  </select>
                  {errors.curlOne && <p>{errors.curlOne.message}</p>}
                </div>
              </div>
              {/* ----------------------------------------------------------------- */}
              <div className="row">
                <div className="col-12">
                  <label htmlFor="curlTwo">Select CURL #2</label>
                </div>
                <div className="col-12">
                  <select
                    name="curlTwo"
                    ref={register({
                      required: "Required",
                      validate: value =>
                        value === watch("curlOne")
                          ? "Curl #1 & Curl #2 Must Be Different"
                          : undefined,
                    })}
                  >
                    {LASH_OPTIONS.map(lash => (
                      <option key={`${lash}_curlTwo`} value={lash}>
                        {lash}
                      </option>
                    ))}
                  </select>
                  {errors.curlTwo && <p>{errors.curlTwo.message}</p>}
                </div>
              </div>
              {/* ----------------------------------------------------------------- */}
              <div className="row">
                <div className="col-12">
                  <label htmlFor="payment">Payment Amount</label>
                </div>
                <div className="col-12">
                  <select
                    name="payment"
                    ref={register({
                      required: "Required",
                    })}
                  >
                    {GROUP_VALID_PAYMENTS.map(payment => (
                      <option key={payment.display} value={payment.value}>
                        {payment.display}
                      </option>
                    ))}
                  </select>
                  {errors.payment && <p>{errors.payment.message}</p>}
                </div>
              </div>
              {/* ----------------------------------------------------------------- */}
              <CardSection label={`Card Details`} />
              {/* ----------------------------------------------------------------- */}
              {renderButton()}
              {/* ----------------------------------------------------------------- */}
              {renderAlert()}
              {/* ----------------------------------------------------------------- */}
            </div>
          </Form>
        </div>
      </div>
    </>
  )
}

const MapStateToProps = state => {
  return {
    loading: state.groupTraining.loading,
    groupDates: state.groupTraining.groupDates,
    groupTrainingRegSuccess: state.groupTraining.groupTrainingRegSuccess,
    groupTrainingRegError: state.groupTraining.groupTrainingRegError,
  }
}

export default connect(
  MapStateToProps,
  actions
)(injectStripe(GroupTrainingRegister))
