import Styled from "styled-components"

const Alert = Styled.span`
    width: 100%;
    padding: .75rem 1.25rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: .25rem;
    text-align: center;
`
const Primary = Styled(Alert)`
    color: #004085;
    background-color: #cce5ff;
    border-color: #b8daff
`
const Secondary = Styled(Alert)`
    color: #383d41;
    background-color: #e2e3e5;
    border-color: #d6d8db;
`
const Success = Styled(Alert)`
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
`
const Danger = Styled(Alert)`
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
`
const Warning = Styled(Alert)`
    color: #856404;
    background-color: #fff3cd;
    border-color: #ffeeba;
`
const Info = Styled(Alert)`
    color: #0c5460;
    background-color: #d1ecf1;
    border-color: #bee5eb;
`

export { Primary, Secondary, Success, Danger, Warning, Info }
